<template lang="pug">
.container-fluid.px-3.goals-page
  .row.mb-4
    .col-9.align-self-center
      om-heading.mt-1(h1) {{ $t('conversionGoals.title') }}
    .col-3.d-flex.justify-content-end.align-items-center
      om-button(primary @click="openCreateGoalModal()") {{ $t('conversionGoals.createNewGoal') }}
  .row
    .col-12.mt-6
      om-heading(h5) {{ $t('conversionGoals.customConversionGoals') }}
    .col-12.custom-goals-container.mb-6
      om-table.custom-goals-table.pt-6(
        :loadingContent="loadingGoals"
        :items="customGoals"
        :columns="columns"
        :columnClasses="columnClasses"
        :sortable="false"
        :selectable="false"
        :allItemsCount="customGoalsCount"
        :pagination="pagination"
        @paginationChange="pagination = $event"
        :limits="[5, 10, 25]"
        @mouseOutFromTableRow="closeKebabMenu($event)"
      )
        template(slot="loading")
          skeleton-loader(type="goal" :rows="5")
        template(slot="name" slot-scope="{ rowData }")
          .d-flex.flex-column
            .custom-goal-name(@click="openEditGoal(rowData.row)") {{ rowData.row.name }}
            om-body-text.custom-goal-description(
              bt400xs
              v-html="getRuleText({ name: rowData.row.name, rules: rowData.row.rules, isDefault: rowData.row.default })"
            )
        template(slot="domain" slot-scope="{ rowData }")
          om-body-text.custom-goal-domain(bt400xs) {{ rowData.row.domain }}
        template(slot="delete" slot-scope="{ rowData }")
          .d-flex.justify-content-end
            om-kebab-menu(
              :rowData="rowData"
              @edit="openEditGoal"
              @duplicate="duplicateGoal"
              @delete="deleteGoal"
            )
              template(slot="duplicate")
                span {{ $t('duplicate') }}
              template(slot="edit")
                span {{ $t('edit') }}
              template(slot="delete")
                span {{ $t('delete') }}
    template
      .col-12.built-in-goals.mb-6.mt-6
        .d-flex.align-items-center
          om-heading(h5) {{ $t('conversionGoals.builtInConversionGoals') }}
          om-tooltip.ml-2(color="#23262a" iconSize="1.5rem") {{ $t('conversionGoals.builtInConversionGoalsTooltip') }}
      .col-12.built-in-goals-container
        template(v-for="defaultGoal in defaultGoals")
          template(
            v-if="defaultGoal.name !== 'addToCart' || hasActiveShopify || hasActiveShopRenter"
          )
            .built-in-goal-name {{ $t('conversionGoals.eventTypes.' + defaultGoal.name) }}
            .built-in-goal-description
              om-body-text(bt400xs) {{ $t('conversionGoals.defaultGoals.' + defaultGoal.name) }}

          hr.my-4
    .col-12.goals-description-container
      goals-description
  create-edit-goal(
    @goals:createGoal="upsertGoalOnGoalsPage"
    @goals:editGoal="upsertGoalOnGoalsPage"
  )
</template>
<script>
  import GoalsDescription from '@/components/Goals/DescriptionModule.vue';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import CreateEditGoal from '@/components/Modals/CreateEditGoal.vue';
  import GET_GOALS from '@/graphql/GetGoals.gql';
  import DELETE_GOAL from '@/graphql/DeleteGoal.gql';
  import goalRules from '@/mixins/goalRules';
  import dateFormat from '@/mixins/dateFormat';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import closeKebabMenu from '@/components/Elements/KebabMenu/closeKebabMenu';

  export default {
    components: { GoalsDescription, SkeletonLoader, CreateEditGoal },
    mixins: [goalRules, dateFormat, closeKebabMenu],
    data() {
      return {
        pagination: { page: 1, limit: 5 },
        loadingGoals: true,
        allConversionGoals: {},
        columnClasses: {
          name: 'text-left goal-name',
          domain: 'text-left goal-domain',
          createdAt: 'goal-createdAt',
          delete: 'goal-delete',
        },
      };
    },
    computed: {
      ...mapGetters(['hasActiveShopify', 'hasActiveShopRenter', 'domains', 'getLocale']),
      defaultGoals() {
        return this.allConversionGoals?.goals?.filter((goal) => goal.default) ?? [];
      },
      customGoals() {
        return (
          this.allConversionGoals?.goals
            ?.filter((goal) => !goal.default)
            ?.map((goal) => {
              return {
                ...goal,
                domain: this.domains.find((domain) => domain._id === goal.domainId)?.domain || '',
                createdAt: moment(goal.createdAt).format(this.dateFormat),
              };
            }) ?? []
        );
      },
      customGoalsCount() {
        return this.allConversionGoals?.count;
      },
      columns() {
        return [
          { header: this.$t('name'), key: 'name' },
          { header: this.$t('domain'), key: 'domain' },
          { header: this.$t('dateCreated'), key: 'createdAt' },
          { header: '', key: 'delete' },
        ];
      },
    },
    watch: {
      pagination() {
        this.getGoals();
      },
    },
    created() {
      this.getGoals();
      this.fetchCampaigns([]);
    },
    methods: {
      async upsertGoalOnGoalsPage(goal) {
        this.loadingGoals = true;
        await this.upsertGoal(goal);
      },
      openEditGoal(goal) {
        this.$modal.show('create-edit-goal', {
          goal,
          mode: 'edit',
        });
      },
      async duplicateGoal(goal) {
        const { _id, createdAt, ...restOf } = goal;
        restOf.name =
          this.getLocale === 'en' ? `Copy of ${restOf.name}` : `${restOf.name} másolata`;

        this.loadingGoals = true;
        await this.upsertGoal(restOf);
      },
      async deleteGoal({ _id }) {
        this.loadingGoals = true;
        try {
          const {
            data: {
              result: { success },
            },
          } = await this.$apollo.mutate({
            mutation: DELETE_GOAL,
            variables: {
              goalId: _id,
            },
          });
          if (success) {
            this.$notify({
              type: 'success',
              text: this.$t('notifications.deleteSuccess'),
            });
            await this.getGoals();
          }
        } catch (e) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.deleteError'),
          });
        }
        this.loadingGoals = false;
      },
      async getGoals() {
        try {
          const {
            data: { goals },
          } = await this.$apollo.query({
            query: GET_GOALS,
            variables: {
              pagination: this.pagination,
            },
          });
          if (goals) {
            this.allConversionGoals = goals;
            this.loadingGoals = false;
          }
        } catch (e) {
          this.loadingGoals = false;
          return [];
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .goals-page::v-deep
    .goals-btns
      flex-direction: row!important
  .custom-goals-table::v-deep
    .brand-table .tbody .brand-table-tr
      min-height: 4.375rem
      padding: .5rem 0

    @media screen and (min-width: 996px)
      .brand-table
        .thead,
        .tbody
          .goal-name
            flex: 0 0 60%

          .goal-domain
            flex: 0 0 25%

          .goal-createdAt
            flex: 0 0 10%

          .goal-delete
            flex: 0 0 5%

    td .goal-createdAt
      color: $om-gray-600
    .brand-table .td.text-left,
    .brand-table .th.text-left
      text-align: left
      display: flex
      justify-content: flex-start

    .brand-table .tbody .brand-table-tr .td
      font-weight: 400

  .built-in-goal
    &-name
      font-size: 1rem
      color: $om-gray-700

    &-description
      .body-text
        color: $om-gray-600

  .goals-description
    margin-top: 40px
  .custom-goal
    &-name
      font-size: 0.938rem
      color: $om-orange-500
      cursor: pointer

    &-description,
    &-domain
      color: $om-gray-600
</style>
